import Img from "gatsby-image"
import React from "react"
import tw from "tailwind.macro"

const Logo = ({ image, title }) => {
  return (
    <Img
      fixed={image}
      objectFit="contain"
      alt={title}
      //loading="eager"
      imgStyle={{
        objectFit: "contain",
      }}
    />
  )
}

export default Logo
