import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Img from 'gatsby-image';
import {
  FaFacebookSquare,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
  FaYoutube,
} from 'react-icons/fa';
import tw from 'tailwind.macro';
import Button from './button';
import Logo from './logo';
import Grid from './grid';
import PrePrimaryNavigation from './prePrimaryNavigation';
import PrimaryNavigation from './primaryNavigation';
import SafeLink from './safeLink';
import Section from './section';

const Header = tw.header`relative shadow-lg z-30`;
const Footer = tw.footer``;

const Layout = ({ location, parentTitle, pageTitle, children }) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "su-logo-maroon.jpg" }) {
        childImageSharp {
          fixed(width: 110, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      logoWhite: file(relativePath: { eq: "su-logo-white-transparent.png" }) {
        childImageSharp {
          fixed(width: 180, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      poster2: file(
        relativePath: { eq: "projects-action4-inclusion-poster-2.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      site {
        siteMetadata {
          title
          titleAlt
        }
      }
    }
  `);

  return (
    <>
      <Header>
        <PrePrimaryNavigation logo={data.logo.childImageSharp.fixed} />
        <PrimaryNavigation
          title={data.site.siteMetadata.titleAlt}
        />
      </Header>
      <main>{children}</main>
      <Footer>
        <Section isThin css={tw`py-10 bg-dark-gray shadow-inner`}>
          <Grid lgGridCols={6} gap={10}>
            <div className="md:col-span-2">
              <Logo
                image={data.logoWhite.childImageSharp.fixed}
                title={data.site.siteMetadata.title}
              />
            </div>
            <div
              className="md:col-start-3 md:col-span-2"
              css={tw`text-dark-gray-300`}
            >
              <nav>
                <h5 css={tw`uppercase tracking-wide`}>
                  Projects of the Chair:
                </h5>
                <ul css={tw`text-dark-gray-300 m-0 p-0`}>
                  <li css={tw`list-none`}>
                    <SafeLink to="/projects/m-plan/" css={tw`hover:text-white`}>
                      The Musa Plan for Social <br />
                      Justice (M-Plan) &rarr;
                    </SafeLink>
                  </li>
                  <li css={tw`list-none`}>
                    <SafeLink
                      to="/projects/data-analytics/"
                      css={tw`hover:text-white`}
                    >
                      Data Analytics &rarr;
                    </SafeLink>
                  </li>
                  <li css={tw`list-none`}>
                    <SafeLink
                      to="/projects/social-justice-cafe/"
                      css={tw`hover:text-white`}
                    >
                      Social Justice Café &rarr;
                    </SafeLink>
                  </li>
                  <li css={tw`list-none`}>
                    <SafeLink
                      to="/projects/everyday-justice/"
                      css={tw`hover:text-white`}
                    >
                      Everyday Justice &rarr;
                    </SafeLink>
                  </li>
                  <li css={tw`list-none`}>
                    <SafeLink
                      to="/projects/action4inclusion/"
                      css={tw`hover:text-white`}
                    >
                      #Action4Inclusion &rarr;
                    </SafeLink>
                  </li>
                  <li css={tw`list-none`}>
                    <SafeLink
                      to="/projects/dear-presidents/"
                      css={tw`hover:text-white`}
                    >
                      Dear Presidents &rarr;
                    </SafeLink>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="md:col-span-2">
              <h5 css={tw`uppercase tracking-wide text-dark-gray-300`}>
                About the Chair:
              </h5>
              <p css={tw`text-dark-gray-300`}>
                Professor Thulisile “Thuli” Madonsela, an advocate of the High
                Court of South Africa, heads the Centre for Social Justice
                and is a law professor at the University of Stellenbosch, where she
                conducts and coordinates social justice research and teaches
                constitutional and administrative law.
              </p>
              <Button to="/about/" variant="outline" css={tw`mr-2 mb-2`}>
                Learn more &rarr;
              </Button>
              <Button to="/collaborate/" variant="outline">
                Collaborate with us &rarr;
              </Button>
            </div>
          </Grid>
        </Section>
        <div css={tw`bg-maroon`}>
          <div className="container" css={tw`flex items-center py-3`}>
            <div
              css={tw`text-maroon-400 text-xs font-semibold uppercase tracking-wide`}
            >
              Copyright &copy; 2020 {data.site.siteMetadata.titleAlt} &middot;{' '}
              <SafeLink to="https://entle.co">Website by Entle</SafeLink>
            </div>

            <SafeLink
              to="https://www.facebook.com/Social-Justice-M-Plan-343817256222320/"
              css={tw`mr-4 md:mr-2 ml-auto`}
            >
              <FaFacebookSquare css={tw`text-lg text-white hover:text-white`} />
            </SafeLink>
            <SafeLink to="https://twitter.com/CFSJ_MPlan">
              <FaTwitter css={tw`text-lg text-white mr-2 hover:text-white`} />
            </SafeLink>
            <SafeLink
              to="https://www.instagram.com/socialjusticechairsu/"
              css={tw`mr-4 md:mr-2`}
            >
              <FaInstagram css={tw`text-lg text-white hover:text-white`} />
            </SafeLink>
            <SafeLink
              to="https://www.youtube.com/channel/UCr9UXixaCB7wURJv2mVEVXA"
              css={tw`mr-4 md:mr-2`}
            >
              <FaYoutube css={tw`text-lg text-white hover:text-white`} />
            </SafeLink>
            <SafeLink to="https://www.linkedin.com/company/law-trust-social-justice-hub">
              <FaLinkedinIn css={tw`text-lg text-white hover:text-white`} />
            </SafeLink>
          </div>
        </div>
      </Footer>
    </>
  );
};

export default Layout;
