import styled from '@emotion/styled';
import { Link } from 'gatsby';
import React, { useState } from 'react';
import tw from 'tailwind.macro';
import Button from './button';
import SafeLink from './safeLink';

const MenuWrapper = styled.nav`
  ${tw`bg-dark-gray`}
`;
const Menu = styled.nav`
  ${tw`py-5 flex justify-between flex-wrap`}
`;
const MenuItems = styled.nav`
  ${tw`md:w-3/4 flex-break md:flex-grow md:flex md:items-center md:justify-between w-full md:w-auto overflow-hidden`};
`;
const DropdownMenu = styled.div`
  ${tw`md:absolute z-50 md:bg-white md:hidden md:opacity-0 md:overflow-hidden md:rounded-b md:rounded-tr md:shadow-md`}

  & .child {
    ${tw`pl-2 md:px-4 md:py-3 md:pr-10 text-sm tracking-normal normal-case`};
  }
`;
const MenuItemLink = styled((props) => <Link {...props} />)`
  ${tw`block py-1 md:px-3 text-dark-gray-200 md:text-dark-gray-800 uppercase tracking-wider border-b md:border-none border-dark-gray-800`};

  &:not(.child) {
    ${tw`pt-5 md:pt-2 text-white font-semibold`};

    &:hover {
      ${tw`text-dark-gray-200`}
    }
  }
  &.child:hover {
    ${tw`text-black`}
  }
`;
const MenuItemDropdown = styled.div`
  &:hover ${MenuItemLink} {
    &:not(.child) {
      ${tw`text-dark-gray bg-white rounded-t`}
    }
  }
  &:hover ${DropdownMenu} {
    ${tw`md:block opacity-100`}
  }
`;
const DropdownIndicator = tw.span`hidden md:inline text-dark-gray-700`;

const PrimaryNavigation = ({ logo, title, titleAlt, children }) => {
  const [isExpanded, toggleExpansion] = useState(false);

  return (
    <MenuWrapper>
      <Menu className="container">
        <SafeLink to="/" css={tw`block mr-10 mb-3 w-full`}>
          <span
            css={tw`block text-white text-lg md:text-2xl font-bold uppercase tracking-wider leading-none`}
          >
            {title}
          </span>
        </SafeLink>
        <Button
          variant="outline"
          css={tw`md:hidden self-center`}
          onClick={() => toggleExpansion(!isExpanded)}
        >
          Menu {String.fromCharCode('9776')}
        </Button>
        <MenuItems
          className={`transition-max-height duration-500 md:max-h-full ${
            isExpanded
              ? `xs:max-h-2x-screen sm:max-h-2x-screen`
              : `xs:max-h-0 sm:max-h-0`
          }`}
        >
          <MenuItemLink
            to="/data-research/"
            className="text-shadow"
            activeClassName="active"
            css={tw`md:pl-0`}
          >
            Data & Research
          </MenuItemLink>
          <MenuItemDropdown className="text-shadow hover:text-shadow-none">
            <MenuItemLink to="/projects/" activeClassName="active">
              Projects <DropdownIndicator>&darr;</DropdownIndicator>
            </MenuItemLink>
            <DropdownMenu className="transition-opacity duration-100">
              <MenuItemLink
                to="/projects/m-plan/"
                className="child"
                activeClassName="active"
              >
                The M-Plan
              </MenuItemLink>
              <MenuItemLink
                to="/projects/action4inclusion/"
                className="child"
                activeClassName="active"
              >
                #ACTION4INCLUSION Campaign
              </MenuItemLink>              
              <hr css={tw`hidden md:block`} />
              <MenuItemLink
                to="/projects/scopra/"
                className="child"
                activeClassName="active"
              >
                COVID-19: SCOPRA
              </MenuItemLink>
              <MenuItemLink
                to="/projects/data-analytics/"
                className="child"
                activeClassName="active"
              >
                Data Analytics
              </MenuItemLink>
              <MenuItemLink
                to="/projects/social-justice-cafe/"
                className="child"
                activeClassName="active"
              >
                Social Justice Café
              </MenuItemLink>
              <MenuItemLink
                to="/projects/everyday-justice/"
                className="child"
                activeClassName="active"
              >
                Everyday Justice
              </MenuItemLink>              
              <MenuItemLink
                to="/projects/dear-presidents/"
                className="child"
                activeClassName="active"
              >
                Dear Presidents
              </MenuItemLink>
            </DropdownMenu>
          </MenuItemDropdown>
          <MenuItemDropdown className="text-shadow hover:text-shadow-none">
            <MenuItemLink to="/blog/" activeClassName="active">
              Blog & Events <DropdownIndicator>&darr;</DropdownIndicator>
            </MenuItemLink>
            <DropdownMenu className="transition-opacity duration-100">
              <MenuItemLink
                to="/conference-2024/"
                className="child"
                activeClassName="active"
              >
                2024 International Social Justice Conference
              </MenuItemLink>
              <MenuItemLink
                to="/summit-2024/"
                className="child"
                activeClassName="active"
              >
                2024 Social Justice Summit
              </MenuItemLink> 
              <hr css={tw`hidden md:block`} />              
              <MenuItemLink
                to="/blog/"
                className="child"
                activeClassName="active"
              >
                Latest articles and events
              </MenuItemLink>
              <hr css={tw`hidden md:block`} />
              <MenuItemLink
                to="/conference-summit-2023/"
                className="child"
                activeClassName="active"
              >
                2023 International Conference and Summit
              </MenuItemLink>      
              <MenuItemLink
                to="/conference-summit-2022/"
                className="child"
                activeClassName="active"
              >
                2022 International Conference and Summit
              </MenuItemLink>
              <MenuItemLink
                to="/summit-conference-2021/"
                className="child"
                activeClassName="active"
              >
                2021 Summit and International Conference
              </MenuItemLink>
              <MenuItemLink
                to="/summit-2020/"
                className="child"
                activeClassName="active"
              >
                2020 Social Justice Summit
              </MenuItemLink>                            
            </DropdownMenu>
          </MenuItemDropdown>
          <MenuItemLink
            to="/blog/2023/03/short-courses-2023/"
            className="text-shadow"
            activeClassName="active"
          >
            Short courses
          </MenuItemLink>
          <MenuItemLink
            to="/about/"
            className="text-shadow"
            activeClassName="active"
          >
            About
          </MenuItemLink>
          <MenuItemLink
            to="/collaborate/"
            className="text-shadow"
            activeClassName="active"
          >
            Collaborate
          </MenuItemLink>
        </MenuItems>
      </Menu>
    </MenuWrapper>
  );
};

export default PrimaryNavigation;
