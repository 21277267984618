import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import tw from 'tailwind.macro';

const Base = tw`inline-block text-600 rounded-full text-xs text-white uppercase font-bold tracking-wider border border-transparent`;
const Variants = {
  default: tw`bg-maroon`,
  muted: tw`bg-light-gray`,
  white: tw`bg-white text-dark-gray`,
  outline: tw`border-white hover:bg-white hover:border-white hover:text-dark-gray`,
  none: tw`p-0 hover:text-white`,
};
const Sizes = {
  xs: tw`px-3 py-1 lg:px-3`,
  sm: tw`px-4 py-2 lg:px-5`,
  md: tw`px-4 py-2 text-sm`,
  lg: tw`px-6 py-2 text-base`,
};

const Button = ({ to, variant, size, type, children, ...other }) => {
  const isInternal = /^\/(?!\/)/.test(to);

  if (type === 'submit') {
    return (
      <input
        type="submit"
        css={[Base, Sizes[size], Variants[variant]]}
        {...other}
      />
    );
  }
  // Use Gatsby Link for internal links, and <a> for others
  if (to && to.startsWith('#')) {
    return (
      <a href={to} css={[Base, Sizes[size], Variants[variant]]} {...other}>
        {children}
      </a>
    );
  }
  if (isInternal) {
    return (
      <Link to={to} css={[Base, Sizes[size], Variants[variant]]} {...other}>
        {children}
      </Link>
    );
  }
  return (
    <a
      href={to}
      css={[Base, Sizes[size], Variants[variant]]}
      {...other}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
};

Button.propTypes = {
  to: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  isExternal: PropTypes.bool,
};

Button.defaultProps = {
  variant: 'default',
  size: 'sm',
};

export default Button;
