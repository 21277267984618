// Default imports
import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const SafeLink = ({ to, children, ...other }) => {
  const isInternal = /^\/(?!\/)/.test(to)

  // Use Gatsby Link for internal links, and <a> for others
  if (isInternal) {
    return (
      <Link to={to} {...other}>
        {children}
      </Link>
    )
  }
  return (
    <a href={to} {...other} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  )
}

SafeLink.propTypes = {
  to: PropTypes.string,  
}

export default SafeLink
