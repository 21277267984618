import React from 'react';
import {
  FaFacebookSquare,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaLinkedinIn,
} from 'react-icons/fa';
import tw from 'tailwind.macro';
import Logo from './logo';
import SafeLink from './safeLink';

const PrePrimaryNavigationContainer = tw.nav`bg-maroon py-1`;

const PrePrimaryNavigation = ({ title, logo }) => {
  return (
    <PrePrimaryNavigationContainer>
      <div
        className="container text-shadow flex items-center"
        css={tw`flex justify-between items-center py-1 text-maroon-500`}
      >
        <SafeLink to="/" css={tw`leading-none`}>
          <Logo image={logo} />
        </SafeLink>
        <SafeLink
          to="https://www.facebook.com/Social-Justice-M-Plan-343817256222320/"
          css={tw`mr-4 md:mr-2 ml-auto leading-none`}
        >
          <FaFacebookSquare css={tw`text-lg hover:text-white`} />
        </SafeLink>
        <SafeLink
          to="https://twitter.com/CFSJ_MPlan"
          css={tw`m-0 leading-none`}
        >
          <FaTwitter css={tw`text-lg mr-2 hover:text-white`} />
        </SafeLink>
        <SafeLink
          to="https://www.instagram.com/socialjusticechairsu/"
          css={tw`mr-4 md:mr-2 leading-none`}
        >
          <FaInstagram css={tw`text-lg hover:text-white`} />
        </SafeLink>
        <SafeLink
          to="https://www.youtube.com/channel/UCr9UXixaCB7wURJv2mVEVXA"
          css={tw`mr-4 md:mr-2 leading-none`}
        >
          <FaYoutube css={tw`text-lg hover:text-white`} />
        </SafeLink>
        <SafeLink
          to="https://www.linkedin.com/company/law-trust-social-justice-hub"
          css={tw`m-0 leading-none`}
        >
          <FaLinkedinIn css={tw`text-lg hover:text-white`} />
        </SafeLink>
      </div>
    </PrePrimaryNavigationContainer>
  );
};

export default PrePrimaryNavigation;
