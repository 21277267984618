// Default imports
import React from "react"
import styled from "@emotion/styled"
import tw from "tailwind.macro"

const StyledSection = styled.section`
  ${tw`py-10 md:py-20`}
  ${props =>
    props.isVeryThin
      ? tw`py-3 md:py-5`
      : props.isThin
      ? tw`py-5 md:py-10`
      : tw`py-10 md:py-20`}
  ${props => props.isShaded && tw`bg-light-gray-100 shadow-inner`}
  ${props =>
    props.isShadedFaded && [
      tw`shadow-inner`,
      `background: linear-gradient(#f4f5f5, #FFF);`,
    ]}
  ${props => props.isDark && tw`bg-dark-gray shadow-inner`}

  h2:first-of-type, h3:first-of-type {
      ${tw`mt-0`}
  }
`

const Section = ({ children, ...other }) => {
  return (
    <StyledSection {...other}>
      <div className="container">{children}</div>
    </StyledSection>
  )
}

export default Section
